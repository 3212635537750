<template>
  <asom-slide-menu v-model="showMenu" title="Discrepancy Report Details">
    <div class="discrepancy">
      <section v-if="selectedTransaction" class="space-y-5 mb-10">
      <dl class="space-y-8 sm:space-y-0">
        <div class='slide-item'>
          <span>Reference No: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'referenceNo', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Discrepancy Type: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'discrepancyType', '-')}}</span>
        </div>
        <div v-if="get(selectedTransaction, 'discrepancyTypeEnum') !== 0" class='slide-item'>
          <span>Transaction No: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'transactionNo', '-')}}</span>
        </div>
        <div v-else class='slide-item'>
          <span>Reason: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'otherReason', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Status: &nbsp;</span>
          <span class="text-content">{{addSpacing(get(selectedTransaction, 'status', '-'))}}</span>
        </div>
        <div class='slide-item'>
          <span>Line: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'line', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Station: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'station', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Date: &nbsp;</span>
          <span class="text-content">{{displayUtcDate(get(selectedTransaction, 'dateTime', '-'))}}</span>
        </div>
        <div class='slide-item'>
          <span>Time: &nbsp;</span>
          <span class="text-content">{{displayUtcDateTime(get(selectedTransaction, 'dateTime', '-'), 'HH:mm')}}</span>
        </div>
     
        <div class='slide-item'>
          <span>Description: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'description', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Parties Involved: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'partiesInvolved', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Action Taken: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'actionTaken', '-')}}</span>
        </div>
        <div v-if="get(selectedTransaction, 'remarks')" class='slide-item'>
          <span>Remarks: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'remarks', '-')}}</span>
        </div>
        <div class='slide-item'>
          <span>Reported By: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'reportedBy', '-')}}</span>
        </div>
        <div v-if="get(selectedTransaction, 'approvedBy')" class='slide-item'>
          <span>Approved By: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'approvedBy', '-')}}</span>
        </div>
        <div v-if="get(selectedTransaction, 'rejectedBy')" class='slide-item'>
          <span>RejectedBy By: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'rejectedBy', '-')}}</span>
        </div>
        <div v-if="get(selectedTransaction, 'assignedOfficer')" class='slide-item'>
          <span>Assigned To: &nbsp;</span>
          <span class="text-content">{{get(selectedTransaction, 'assignedOfficer', '-')}}</span>
        </div>
    
        <asom-form-field
          class="col-span-2"
          v-if="selectedRecordAttachments.length > 0"
          label="Supporting Document"
        >
          <asom-upload-file-list
            :files="selectedRecordAttachments"
            disabled-remove
          />
        </asom-form-field>
      </dl>
      <article class="space-y-4 pt-6" v-if="!isClosed">
        <section class="flex justify-end gap-4">
          <asom-button
            outline
            v-if="canApprove"
            variant="success"
            size="sm"
            text="Approve"
            @click="toggleApproveReportConfirmation(true)"
          />
          <asom-button
            outline
            v-if="canReject"
            variant="error"
            size="sm"
            text="Reject"
            @click="toggleRejectReportConfirmation(true)"
          />
          <asom-button
            v-if="canAssign"
            outline
            size="sm"
            text="Assign"
            @click="toggleAssignReportModalVisibility(true)"
          />
          <asom-button
            outline
            v-if="canClose"
            variant="error"
            size="sm"
            text="Close"
            @click="toggleCloseReportConfirmation(true)"
          />
          <asom-button
            v-if="canUpdate"
            outline
            size="sm"
            text="Update Report"
            @click="updateReport"
          />
        </section>
      </article>
    </section>
    </div>
    <asom-modal
      v-model="showRejectReportConfirmation"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <div class="space-y-4">
        <p class="pt-4">Are you sure you would like to reject this report?</p>
        <asom-form-field
          label="Remarks"
          required
          description="Remarks is required for rejection"
        >
          <asom-input-textarea v-model="rejectReport.remarks" />
        </asom-form-field>
        <div class="flex flex-row-reverse gap-4">
          <asom-button
            @click="onConfirmRejectReport"
            :disabled="isSubmittingRejectReport || emptyRejectReportRemarks"
            :loading="isSubmittingRejectReport"
            text="Confirm"
          />
          <asom-button
            @click="toggleRejectReportConfirmation(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      v-model="showCloseReportConfirmation"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <div class="space-y-4">
        <p class="pt-4">Are you sure you would like to Close this report?</p>
        <asom-form-field
          label="Remarks"
          required
          description="Remarks is required to Close"
        >
          <asom-input-textarea v-model="closeReport.remarks" />
        </asom-form-field>
        <div class="flex flex-row-reverse gap-4">
          <asom-button
            @click="onConfirmCloseReport"
            :disabled="isSubmittingCloseReport || emptyCloseReportRemarks"
            :loading="isSubmittingCloseReport"
            text="Confirm"
          />
          <asom-button
            @click="toggleCloseReportConfirmation(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      v-model="showApproveReportConfirmation"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <div class="space-y-4">
        <p class="pt-4">Are you sure you would like to approve this report?</p>
        <div class="flex flex-row-reverse gap-4">
          <asom-button
            @click="onConfirmApproveReport"
            :disabled="isSubmittingApproveReport"
            :loading="isSubmittingApproveReport"
            text="Confirm"
          />
          <asom-button
            @click="toggleApproveReportConfirmation(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      v-model="showAssignReportModal"
      title="Assign Report"
      :dismissible="false"
    >
      <form>
        <asom-alert v-if="error" variant="error" :error-message="error" />
        <p class="pt-4">Please select an officer of role FSO</p>
        <asom-form-field
          :label="'Officer Assigned'"
          required
          :state="inputStates('formData.assignedOfficer')"
          error="Select an officer"
        >
          <asom-input-select
            :state="inputStates('formData.assignedOfficer')"
            v-model="formData.assignedOfficer"
            :loading="isLoadingOfficers"
            :options="officerOptions"
            @search-change="queryOfficerList"
            placeholder="Type to search for officer"
          />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button
            @click="onConfirmAssignReport"
            :disabled="isSubmittingAssignReport"
            :loading="isSubmittingAssignReport"
            text="Confirm"
          />
          <asom-button
            @click="toggleAssignReportModalVisibility(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </form>
    </asom-modal>
  </asom-slide-menu>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { displayUtcDate, displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import { listUsers } from "@/services/user.service";
import {
  rejectDiscrepancyReport,
  closeDiscrepancyReport,
  assignDiscrepancyReport,
  approveDiscrepancyReport,
} from "@/services/cashManagement.service";
import { mapGetters } from "vuex";

export default {
  props: {
    selectedTransaction: {
      type: undefined,
    },
    modelValue: {
      type: Boolean,
    },
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  emits: ["update:modelValue", "assign", "close"],
  data() {
    return {
      error: null,
      showCloseReportConfirmation: false,
      showApproveReportConfirmation: false,
      showRejectReportConfirmation: false,
      isSubmittingRejectReport: false,
      isSubmittingCloseReport: false,
      showAssignReportModal: false,
      formData: {
        assignedOfficer: null,
      },
      rejectReport: {
        remarks: null,
      },
      closeReport: {
        remarks: null,
      },
      officerOptions: [],
      isLoadingOfficers: false,
      isSubmittingAssignReport: false,
      isSubmittingApproveReport: false,
    };
  },
  validations() {
    return {
      formData: {
        assignedOfficer: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      isSOM: "auth/isSOM",
      canTakeAction: "auth/canAssignDiscrepancyReport",
      canUpdateReport: "auth/canUpdateDiscrepancyReport",
      canApproveOrReject: "auth/canApproveOrRejectDiscrepancyReport",
    }),
    showMenu: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    isClosed() {
      return get(this.selectedTransaction, "status") === "Closed";
    },
    isApproved() {
      return get(this.selectedTransaction, "status") === "Approved";
    },
    isRejected() {
      return get(this.selectedTransaction, "status") === "Rejected";
    },
    canReject() {
      return this.canApproveOrReject && !this.isApproved && !this.isRejected && !this.isClosed;
    },
    canAssign() {
      return (
        this.canTakeAction &&
        !this.isClosed &&
        !this.isRejected && 
        this.isApproved &&
        get(this.selectedTransaction, "assignedOfficerId") === null
      );
    },
    canApprove() {
      return (
        this.canApproveOrReject &&
        !this.isClosed &&
        !this.isApproved && 
        !this.isRejected
      );
    },
    canClose() {
      return this.canTakeAction && this.isApproved && !this.isRejected && 
      !this.isClosed && get(this.selectedTransaction, "assignedOfficerId", null) === this.userId;
    },
    canUpdate() {
      return (
        this.canUpdateReport &&
        !this.isClosed &&
        (
          get(this.selectedTransaction, "assignedOfficerId") === this.userId ||
          (get(this.selectedTransaction, "reportedById") === this.userId &&
          !this.isClosed && !this.isApproved && !this.isRejected)
        )
      );
    },
    emptyCloseReportRemarks() {
      return (
        isEmpty(this.closeReport.remarks) ||
        isEmpty(this.closeReport.remarks.trim())
      );
    },
    selectedRecordAttachments() {
      return get(this.selectedTransaction, "attachments", []);
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcDateTime,
    addSpacing(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = [];
      }
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    toggleCloseReportConfirmation(showModal) {
      this.error = null;
      this.showCloseReportConfirmation = showModal;
    },
    toggleRejectReportConfirmation(showModal) {
      this.error = null;
      this.showRejectReportConfirmation = showModal;
    },
    toggleAssignReportModalVisibility(value) {
      this.error = null;
      this.showAssignReportModal = value;
    },
    toggleApproveReportConfirmation(value) {
      this.error = null;
      this.showApproveReportConfirmation = value;
    },
    onConfirmAssignReport() {
      if (this.isSubmittingAssignReport) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      } else {
        this.confirmAssignReport();
      }
    },
    async confirmAssignReport() {
      this.isSubmittingAssignReport = true;
      const result = await assignDiscrepancyReport({
        assignedOfficerId: get(this.formData.assignedOfficer, "value", null),
        reportId: get(this.selectedTransaction, "discrepancyReportId", ""),
      });
      if (result.success) {
        this.isSubmittingAssignReport = false;
        this.toggleAssignReportModalVisibility(false);
        this.$router.go();
      } else {
        this.isSubmittingAssignReport = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onConfirmApproveReport() {
      if (this.isSubmittingApproveReport) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      this.confirmApproveReport();
    },
    async confirmApproveReport() {
      this.isSubmittingApproveReport = true;
      const result = await approveDiscrepancyReport({
        approvedOfficerId: this.userId,
        reportId: get(this.selectedTransaction, "discrepancyReportId", ""),
      });
      if (result.success) {
        this.isSubmittingApproveReport = false;
        this.toggleApproveReportConfirmation(false);
        this.$emit("update:modelValue", false);
        this.$emit("approve");
      } else {
        this.isSubmittingApproveReport = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async onConfirmRejectReport() {
      this.isSubmittingRejectReport = true;
      const resp = await rejectDiscrepancyReport({
        reportId: get(this.selectedTransaction, "discrepancyReportId", ""),
        remarks: this.rejectReport.remarks,
      });
      if (resp && resp.success) {
        this.toggleRejectReportConfirmation(false);
        this.$emit("update:modelValue", false);
        this.$emit("reject");
        this.rejectReport.remarks = null;
      } else {
        this.error = resp.payload;
      }
      this.isSubmittingRejectReport = false;
    },
    async onConfirmCloseReport() {
      this.isSubmittingCloseReport = true;
      const resp = await closeDiscrepancyReport({
        reportId: get(this.selectedTransaction, "discrepancyReportId", ""),
        remarks: this.closeReport.remarks,
      });
      if (resp && resp.success) {
        this.toggleCloseReportConfirmation(false);
        this.$emit("update:modelValue", false);
        this.$emit("close");
        this.closeReport.remarks = null;
      } else {
        this.error = resp.payload;
      }
      this.isSubmittingCloseReport = false;
    },
    updateReport() {
      this.$router.push({
        name: "Update Discrepancy Report",
        params: { id: get(this.selectedTransaction, "discrepancyReportId") },
      });
    },
  },
};
</script>

<style scope>
.discrepancy{
  height: 100%;
  overflow-y: auto;
  padding: 0 10px 40px 0;
}

.slide-item {
  margin-top: 0px!important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-content {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  word-wrap: break-word; /* Ensures long words break to the next line */
  overflow-wrap: break-word; /* Ensures long words break to the next line */
  white-space: normal; /* Allows text to wrap normally */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */
}
</style>
